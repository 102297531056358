<template>
  <div class="flex flex-col w-full bg-stone-white border border-[#00A1AA99] rounded p-5">
    <div v-if="showTestLists.length > 0">
       <BaseImageLink imageLink="c-info.svg" class="mb-6"
        v-if="showTestLists.length == maxTestList"
        :title="$t('checkoutMultipleTests.maxTestsTitle', {maxTests: maxTestList})">
       </BaseImageLink>
     
      <h4
        class="text-start font-bold text-primary"
      >{{ $t("checkoutMultipleTests.chosenTest") }}</h4>
      <div
        v-for="(item, key) in showTestLists"
        :key="key"
        :class="{'border-b border-b-primary-light':showTestLists.length > 1}"
        class="flex items-center w-full py-2 px-1"
      >
        <NuxtImg src="/icons/circle-ctrl-right.svg" class="w-3 mr-1" alt="ctrl-right" />
        <span class="text-sm text-start font-semibold">{{ item.title }}</span>
        <div class="ml-auto" v-if="showTestLists.length > 1">
          <NuxtImg
            src="/icons/delete-forever.svg"
            class="w-3 h-3 cursor-pointer"
            @click="onRemove(item)"
            alt="delete"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex items-center w-full py-1">
      <BaseImageLink imageLink="c-remove.svg" 
     
        :title="$t('checkoutMultipleTests.noTests')">
       </BaseImageLink>
     


      
    </div>

    <div v-if="getClinicDetail" class="mt-5">
      <h4
        class="text-start font-bold text-primary"
      >{{ $t("checkoutMultipleTests.chosenClinic") }}</h4>
      <div class="flex py-2 px-1" :class="{'border-b border-b-primary-light' : showRemoveClinicOption}">
        <span class="text-sm text-start font-semibold">{{ getClinicDetail.title }}</span>
        <div class="ml-auto" v-if="showRemoveClinicOption">
          <a class="text-secondary flex items-center gap-2" href="javascript:void(0)" @click="removeClinic">
            <span class="text-[0.75em]">{{ $t("checkoutMultipleTests.changeClinic") }}</span>
            <svg 
              class="text-secondary text-[0.75em] scale-90"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" 
              width="12" 
              height="12" 
              viewBox="0 0 12 12"
            >
              <g class="nc-icon-wrapper" fill="none" stroke="currentColor">
                <polyline 
                  points="9 6 11.5 8.5 9 11" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  data-color="color-2"
                ></polyline> 
                <line 
                  x1="0.5" y1="8.5" x2="11.5" y2="8.5" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  data-color="color-2"
                ></line> 
                <polyline 
                  points="3 6 0.5 3.5 3 1" 
                  stroke-linecap="round" 
                  stroke-linejoin="round"
                ></polyline> 
                <line 
                  x1="0.5" y1="3.5" x2="11.5" y2="3.5" 
                  stroke-linecap="round" 
                  stroke-linejoin="round"
                ></line>
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useCheckoutStore } from "~/stores/checkout";
const checkoutStore = useCheckoutStore();
const props = defineProps({
  showTestLists: {
    type: Array
  },
  getClinicDetail: {
    type: Object
  },
  showRemoveClinicOption: {
    type: Boolean,
    required: false,
    default: false,
  }
});
const emit = defineEmits(["onTabChange", "removeClinic"]);
const maxTestList = checkoutStore.maxTestLists;
function onRemove(item) {
  checkoutStore.setCollection(item);
}
function removeClinic() {
  emit("removeClinic");
}
</script>
